//staging
// export const tourLink = 'https://stag.tour1.acdexpo.com/'

// export const walkthroughLink = 'https://stag.tour2.acdexpo.com/'
// export const walkthroughVideoLink = "walkthroughVideo.mp4"

// export const baseAPI = 'https://stag.api.acdexpo.com'
// export const baseAPI = 'http://loreal.eu-west-1.elasticbeanstalk.com'

//prod
export const tourLink = 'https://tour.dermexpo.com/'

export const walkthroughVideoLink = 'https://d1w7gsg954za9g.cloudfront.net/%5BDONT_DELETE%5DwalkThroughVideoAsset/Microbiome_Walkthrough_2024%5B1%5D.mp4'

export const baseAPI = 'https://stage.api.dermexpo.com'

// Aud countdown values
export const audCDWidth = '18%'
export const audCDHeight = '23%'
export const audCDTop = '39%'
export const audCDLeft = '41.25%'

//Aud steam values

export const audtop= "32vh";
export const audleft= "0.25%";
export const audheight= "42%";
export const audwidth= "57%";

export const winnerBtnLeft = '9vw'; //default 16vw
export const chatBtnRight = '9vw'; //default 16vw

export const mobileWidth = 500;

//consent form

export const enableConsentForm = true;

